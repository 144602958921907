import { Button } from '@mr-yum/frontend-ui'
import { useLogger } from 'hooks/useLogger'
import {
  useUnlinkGuestFromMembershipMutation,
  VenueMembershipPayload,
} from 'lib/gql'
import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CombinedError } from 'urql'

export type UnlinkMembershipError = Error | CombinedError

export type OnUnlinkMembershipError = (
  error: UnlinkMembershipError,
) => Promise<any>

export type UnlinkMembershipSuccess = {
  success: boolean
}

export type OnUnlinkMembershipSuccess = (
  data?: UnlinkMembershipSuccess,
) => Promise<any>

interface Props {
  venueId: string
  guestId: string
  program: Pick<VenueMembershipPayload, 'id' | 'configuration' | 'membership'>
  onError: OnUnlinkMembershipError
  onSuccess: OnUnlinkMembershipSuccess
}

export const RewardMembershipUnlinkCTA = ({
  guestId,
  program,
  onError,
  onSuccess,
}: Props) => {
  const intl = useIntl()
  const { logEvent } = useLogger()
  const { configuration, membership } = program
  const [
    { fetching: unlinkGuestFromMembershipLFetching },
    unlinkGuestFromMembership,
  ] = useUnlinkGuestFromMembershipMutation()

  const handleSubmit = useCallback(async () => {
    try {
      const { error, data } = await unlinkGuestFromMembership({
        input: {
          programId: program.id,
          membershipId: membership.id!,
          guestId,
        },
      })
      if (error || !data?.unlinkGuestFromMembership.success) {
        return onError(
          new Error(
            intl.formatMessage({
              defaultMessage: 'Unable to unlink membership. Please try again',
              id: 'zAWXpA',
            }),
          ),
        )
      }
      await onSuccess()
    } catch (error) {
      return onError(error)
    }
  }, [
    intl,
    program,
    membership,
    guestId,
    onError,
    onSuccess,
    unlinkGuestFromMembership,
  ])

  return (
    <div
      className="rounded-2xl bg-surface-subtle p-5 text-foreground"
      data-testid="unlink-membership-cta"
    >
      <p className="mb-3 my-body-md">
        <FormattedMessage
          defaultMessage="Your <b>{displayName} account</b> is linked and you will earn points with every purchase."
          id="XnHl0z"
          values={{
            displayName: configuration.displayName,
            b: (chunks) => <strong>{chunks}</strong>,
          }}
        />
      </p>

      <Button
        size="sm"
        className="!py-3"
        onClick={async () => {
          logEvent('Clicks on Unlink membership CTA in guest profile page')
          await handleSubmit()
        }}
        as="a"
        isLoading={unlinkGuestFromMembershipLFetching}
        fullWidth
      >
        <FormattedMessage
          defaultMessage="Unlink your {displayName} account"
          id="UXsv5H"
          values={{
            displayName: configuration.displayName,
          }}
        />
      </Button>
    </div>
  )
}
