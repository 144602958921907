import { Button } from '@mr-yum/frontend-ui'
import { CartItem } from 'components/Common/CartItem'
import { Sheet } from 'components/Common/Sheet'
import { useLogger } from 'hooks/useLogger'
import type { CartItemPartsFragment } from 'lib/gql'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'

import { Quantities } from './AnotherRoundModal'

interface Props {
  isSubmitting: boolean
  cartItems: CartItemPartsFragment[]
  onSubmit: (quantities: Quantities) => void
  onClose: () => void
  children?: ReactNode
}

export const AnotherRoundSheet = ({
  isSubmitting,
  cartItems,
  onSubmit,
  onClose,
  children,
}: Props) => {
  const { logEvent } = useLogger()
  const [quantities, setQuantities] = useState<Quantities>(
    cartItems.reduce<Quantities>((acc, curr) => {
      return {
        ...acc,
        [curr.id]: curr.quantity,
      }
    }, {}),
  )

  const selectedCartItems = useMemo(
    () =>
      cartItems.filter((cartItem) => {
        return quantities[cartItem.id]
      }),
    [quantities, cartItems],
  )

  const cartItemCount = useMemo(
    () =>
      Object.values(quantities).reduce((acc, curr) => {
        return acc + curr
      }, 0),
    [quantities],
  )

  const makeHandleOnChange = (itemId: string) => (value: number) => {
    setQuantities({ ...quantities, [itemId]: value })
  }

  useEffect(() => {
    logEvent('Another Round modal opened')

    return () => {
      logEvent('Another Round modal closed')
    }
  }, [logEvent])

  // user has removed all items
  useEffect(() => {
    if (!cartItemCount) {
      onClose()
    }
  }, [cartItemCount, onClose])

  return (
    <Sheet
      isCloseable
      showBack={false}
      title={
        <div className="text-center">
          <p className="my-heading-md">Another round?</p>
          <p className="mt-1 my-body-sm">
            Enjoy drinks from your previous order again
          </p>
        </div>
      }
      onClose={onClose}
    >
      <div className="p-4">
        {children && children}
        {selectedCartItems.map((item) => (
          <CartItem
            key={item.id}
            isShowingImage
            minQuantity={0}
            cartItem={item}
            quantity={quantities[item.id] ?? item.quantity}
            onChange={makeHandleOnChange(item.id)}
            notes={item.notes}
          />
        ))}
      </div>
      <div className="border p-4">
        <Button
          data-testid="add-items-button"
          isLoading={isSubmitting}
          className="w-full"
          size="lg"
          onClick={() => onSubmit(quantities)}
        >
          {`Add ${cartItemCount} items`}
        </Button>
      </div>
    </Sheet>
  )
}
